.characters {
    background: $cream;
}

.character {
    .tns-controls {
        left: 0;
        right: 0;
    }
    .character {
        &__subtitle {
            p {
                margin-bottom: 0;
                color: $white;
            }
        }
        &__info {
            img {
                @media (max-width: $s) {
                    max-width: 80%;
                }
            }
            h4 {
                color: $orange;
                font-size: 40px;
                line-height: 49px;
                margin-bottom: 16px;
                @media (max-width: $s) {
                    text-align: center;
                    font-size: 30px;
                    line-height: 39px;
                }
            }
            p {
                margin-bottom: 32px;
                font-size: 20px;
                line-height: 27px;
                @extend %secondary-font;
                color: $black;
                @media (max-width: $s) {
                    text-align: center;
                }
            }
        }
        &__related-books {
            &__title {
                background-color: $orange;
                padding: 12px 16px 9px 16px;
                margin-bottom: 16px;

                h4 {
                    color: $white;
                    font-size: 25px;
                    line-height: 30px;
                    text-transform: uppercase;
                    margin-bottom: 0;
                }
            }
            .books-carousel {
                .books-container {
                    align-items: center;
                }
                .tns-controls {
                    left: -10px;
                    right: -10px;
                }
                .book-card {
                    padding: 16px;
                    &__image {
                        min-height: unset;
                    }
                }
            }
        }
    }
}
