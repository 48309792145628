@import "flextabs/flextabs";

@include breakpoint(md) {
  .flextabs__tab {
    /* shift all tabs to appear before content */
    order: -1;
    /* let tabs scale to fit multiple on each row */
    width: auto;
  }
  .flextabs__content--active {
    /* ignore states activated for multi (accordion) toggle view */
    display: none;
  }
  .flextabs__content--active--last {
    /* show the last activated item */
    display: block;
  }
}

.flextabs {
  margin-top: 50px;
  &__toggle {
    padding: 10px 0;
    @include breakpoint(md) {
      padding: 10px 20px;
      &--active--last {
        background: $white;
        border-top: 1px solid $black;
        border-left: 1px solid $black;
        border-right: 1px solid $black;
        border-bottom: 1px solid $white;
        margin-bottom: -1.5px;
        position: relative;
        z-index: 1;
      }
    }
  }
  &__content {
    padding: 20px 0px;
    position: relative;
    @include breakpoint(md) {
      min-height: 230px;
      &--active--last {
        background: $white;
        border-top: 1px solid $black;
      }
      &:before,
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        background: $white;
        width: 9999px;
        z-index: 3;
        height: calc(100% + 1px);
        top: -1px;
        border-top: 1px solid $black;
      }
      &:before {
        right: 100%;
      }
      &:after {
        left: 100%;
      }
    }
    &.author-tab {
      p {
        img {
          display: inline-block;
          margin-bottom: 5px;
        }
      }
    }
  }
  &__tab {
    margin: 0;
  }
}
